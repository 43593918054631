import React from 'react';

import Layout from '../../components/Layout';

export default function Hausnotruf() {
	return (
		<Layout title="Hausnotruf">
			<h2>Hausnotruf</h2>
			<p>
				Die BRK Bereitschaft Bad Reichenhall stellt für den Hausnotrufdienst des Bayerischen Roten Kreuzes einen
				sogenannten Hintergrunddienst bereit.
			</p>
			<p>
				Freiwillige Hintergrundhelfer werden durch die Hausnotrufzentrale Starnberg über Telefon/Handy alarmiert
				und fahren dann mit ihrem privaten PKW zur hilfsbedürftigen Person, um sie aus ihrer Notlage zu
				befreien.
			</p>
			<p>
				Zudem fungiert der Hintergrunddienst auch als Bezugsperson in Abwesenheit von Angehörigen, Nachbarn,
				oder als Unterstützung (Hebehilfe) für Pflegedienste vor Ort.
				<p></p>
				Bei akuten Notfällen wird sofort der Rettungsdienst alarmiert.
			</p>
			<p>
				Mehr Infos zum Hausnotruf finden Sie auf der Seite zum{' '}
				<a href="https://www.brk-bgl.de/angebote/alltagshilfen/hausnotruf.html" target="_blank">
					Hausnotruf
				</a>{' '}
				des BRK Kreisverbandes Berchtesgadener Land.
			</p>
			<p>
				<b>Ihr Ansprechpartner:</b>
				<br />
				Herr Eric Wierzchowski
				<br />
				Mail: wierzchowski[at]kvbgl.brk.de
				<br />
				Bitte [at] durch @ ersetzen!
			</p>
		</Layout>
	);
}
